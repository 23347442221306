const NUCLEUZ_IDS_PREFIX = "ci-";
const EDM_IDS_PREFIX = "edm-";

export const isNucleuz = (name?: string) => name && name.startsWith(NUCLEUZ_IDS_PREFIX);

export const isEdm = (name?: string) => name && name.startsWith(EDM_IDS_PREFIX);

// Nucleuz ids are based on names, so the reverse operation will give us the name of the Nucleuz policy
export const nucleuzName = (name: string) => ruleName(name, NUCLEUZ_IDS_PREFIX);

export const edmName = (name: string) => ruleName(name, EDM_IDS_PREFIX);

export const ruleName = (name: string, prefix: string = "") => {
  return name
    .replace(prefix, "")
    .replace(/-/g, " ")
    .split(" ")
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};
