// Reexport apis with friendlier names and more suitable types overrides
import { pick } from "lodash";
import { api, types, riskydashboard } from "api";
import { types_CategoryStatusEnum, irm } from "api/gen";
import { compareBy } from "libs/data-utils";
import { CELConditionOperator, celCombine } from "libs/cel-query";

export const {
  DeleteCategory: deleteCategory,
  AddCategory,
  DeleteDataset: deleteDataset,
  CategoriesCountV2: categoriesCount,
  Locations: locationCount,
  TotalCount: totalCount,
  UsersCount: usersCount,
  ListIncidents: listIncidents,
  ResolveIncidents: resolveIncidents,
  IncidentListView: incidentsListView,
  CountUnresolvedIncidents: countUnresolvedIncidents,
} = api.riskydashboard.ServiceInterface;

export const createCategory = (entity: types.Category) => {
  return api.riskydashboard.ServiceInterface.AddCategory({
    category: removeExcessSelectAllValues(entity, "dataset_ids"),
  });
};
export const updateCategory = (entity: types.Category) => {
  return api.riskydashboard.ServiceInterface.UpdateCategory({
    category: removeExcessSelectAllValues(entity, "dataset_ids"),
  });
};

export const listCategories = () => {
  return api.riskydashboard.ServiceInterface.ListCategories({});
};

function removeExcessSelectAllValues<T>(entity: T, field: keyof T) {
  return {
    ...entity,
    [field]: (entity[field] as string[])?.filter((id: string) => !["a", "l", "all"].includes(id)),
  };
}

export const createDataset = (entity: riskydashboard.DatasetDTO) => {
  return api.riskydashboard.ServiceInterface.AddDataset({
    dataset: removeExcessSelectAllValues(entity, "category_ids"),
  });
};

export const updateDataset = (entity: riskydashboard.DatasetDTO) => {
  return api.riskydashboard.ServiceInterface.UpdateDataset({
    dataset: removeExcessSelectAllValues(entity, "category_ids"),
  });
};

export const listDatasets = () => {
  return api.riskydashboard.ServiceInterface.ListDatasets({});
};

export const timesCount = (params: riskydashboard.TimesCountRequest) => {
  return api.riskydashboard.ServiceInterface.TimesCount(params).then((d) => {
    if (!d.dataflows_counts) {
      return [];
    }
    return (d.period_starts || []).map((period, i) => ({
      date: period,
      flows: d.dataflows_counts?.[i],
      users: d.users_counts?.[i],
      locations: d.locations_counts?.[i],
    }));
  });
};

export function mapStatuses(statuses: Record<types.CategoryStatus, number>) {
  let sum = 0;
  const map = {} as Record<types.CategoryStatus, number>;
  const values = [] as any[];

  function mapValues(name: any, count: any) {
    count = count ?? 0;
    map[name as types.CategoryStatus] = count;
    sum += count;
    values.push({
      name,
      count,
    });
  }

  for (const k in statuses) {
    mapValues(k, statuses[k as types.CategoryStatus]);
  }
  return {
    statusesMap: map,
    statusesList: values,
    count: sum,
  };
}

export function mapCategoriesResponse(d: riskydashboard.CategoriesCountV2Response) {
  return {
    records: (d.categories || [])
      .map((el) => {
        const name = el.id === "Uncategorized" ? "Unmatched" : el.name;
        return {
          ...el,
          name,
          ...mapStatuses(pick(el.status_to_count!, [el.rule.status, "needs_review"])),
        };
      })
      .sort(compareBy((el) => el.count)),
  };
}

export type GetCategoriesCountsRequestType = riskydashboard.CategoriesCountV2Request & {
  categories_filter: riskydashboard.DatasetsCountV2Request["categories_filter"];
  is_search: boolean;
};
export async function getCategoriesCounts({
  is_search = false,
  ...params
}: GetCategoriesCountsRequestType) {
  if (params.categories_filter && params.categories_filter?.preview_category?.category) {
    const currCategory = params.categories_filter.preview_category.category;
    const categoryStatus: types.CategoryStatus = is_search
      ? types_CategoryStatusEnum.CategoryStatusNeedsReview
      : currCategory.rule.status;
    const res = await api.riskydashboard.ServiceInterface.TotalCount({
      ...params,
      statuses: [currCategory.rule?.status ?? types_CategoryStatusEnum.CategoryStatusAny],
    });
    const count = Object.values(res.counts!).reduce((acc, el) => acc + el, 0);

    return mapCategoriesResponse({
      categories: [
        {
          ...currCategory,
          name: currCategory.name,
          rule: {
            ...params.categories_filter?.preview_category.category.rule,
            status: categoryStatus,
          },
          status_to_count: {
            [categoryStatus]: count,
          },
        },
      ] as any,
    });
  }
  return api.riskydashboard.ServiceInterface.CategoriesCountV2(params).then((res) => {
    return mapCategoriesResponse(res);
  });
}
export function mapDatasetsResponse(d: riskydashboard.DatasetsCountV2Response) {
  return {
    records: (d.records || [])
      .map((el) => ({
        ...el,
        ...mapStatuses(el.status_to_count!),
      }))
      .sort(compareBy((el) => el.count)),
  };
}

export function getDatasetsCounts(params: riskydashboard.DatasetsCountV2Request) {
  return api.riskydashboard.ServiceInterface.DatasetsCountV2(params).then(mapDatasetsResponse);
}

// TODO: Amirov temporary until api for getting connections is ready
export type DatasetConnections = Record<string, { status: types.CategoryStatus; count: number }>;
export async function getDatasetsConnectionsList(
  datasetsIds: string[],
  params: riskydashboard.CategoriesCountV2Request
) {
  const promises = datasetsIds.map((id) => {
    const requestParams = {
      ...params,
      datasets_filter: { datasets: [id], all_datasets: false },
    };

    return getDatasetConnections(id, requestParams);
  });

  return Promise.allSettled(promises).then((results) => {
    const allConnections: Record<string, DatasetConnections> = {};

    results.forEach((result) => {
      if (result.status === "fulfilled") {
        const { id, connections } = result.value;

        allConnections[id] = connections;
      }
    });

    return allConnections;
  });
}

export async function getDatasetConnections(
  id: string,
  params: riskydashboard.CategoriesCountV2Request
) {
  return api.riskydashboard.ServiceInterface.CategoriesCountV2(params).then((res) => {
    const categories = mapCategoriesResponse(res).records;

    const connections: DatasetConnections = {};

    categories
      .filter(
        (category) =>
          category.count > 0 && category.rule.status !== types_CategoryStatusEnum.CategoryStatusAny
      )
      .forEach((category) => {
        connections[category.id!] = {
          status: category.rule.status,
          count: category.count,
        };
      });

    return { id, connections };
  });
}

export async function getTotalCounts(params: riskydashboard.TotalCountRequest) {
  const { counts } = await api.riskydashboard.ServiceInterface.TotalCount(params);

  return counts;
}

export async function getLocationSubItems(
  params: riskydashboard.LocationsCountV2Request,
  location: types.LocationExpansion
) {
  return locationCount({
    ...params,
    page: 0,
    page_size: 1,
    no_default_expansions: true,
    expansions: [location],
  });
}

export function getRiskyUsers(params: irm.RiskyUsersRequest) {
  return api.irm.Service.GetRiskyUsers(params).then((response) => {
    const nextPage =
      response.records?.length && params.offset + response.records.length < response.total
        ? params.offset + response.records.length
        : undefined;

    return {
      total: response.total,
      result: response.records || [],
      nextPage,
    };
  });
}

export function getUserRiskLevels() {
  return api.irm.Service.GetUserRiskLevels({});
}

export function getUserRiskProgression({
  userId,
  times_filter,
  period,
  filter,
}: {
  userId: string;
  times_filter: types.TimesFilter;
  period: types.Period;
  filter: string;
}): Promise<{ risks: Array<{ risk: number; date: string }>; type: types.Period }> {
  return api.irm.Service.GetTimeline({
    times_filter,
    metrics: ["risk_score"],
    period_type: period,
    filter: celCombine(CELConditionOperator.AND, `id == '${userId}'`, filter),
  }).then(({ records }) => {
    return {
      risks: records!.map((record) => ({
        risk: record.metrics!.risk_score,
        date: record.date,
      })),
      type: period,
    };
  });
}

export async function getRiskyUserHistory({
  userId,
  page = 0,
  pageSize = 50,
}: {
  userId: string;
  page?: number;
  pageSize?: number;
}): Promise<{ actions: irm.RiskyUserAction[]; nextPage: number; hasMore: boolean }> {
  const response = await api.irm.Service.ListUserManagementActions({
    user_id: userId,
    offset: page * pageSize,
    page_size: pageSize,
  });

  return {
    actions: response.actions ?? [],
    nextPage: page + 1,
    hasMore: response.actions?.length === pageSize,
  };
}
