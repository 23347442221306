import { useSelector } from "react-redux";
import { updateSensitiveValues } from "runenv";
import { useQuery, QueryClient, useQueryClient } from "@tanstack/react-query";
import { api, types, apiCustom, frontendconfig } from "api";
import { GetCurrentUserScopeDocument, GetUserPermissionsDocument } from "api/graphql";
import { sendGQLRequest } from "api/graphql/network";
import { updatePolicyDefaults } from "./dashboard";

const userKey = "user";
const configKey = "frontend-config";

const CACHE_STALE_TIME = 15 * 60 * 1000;

export function useCurrentUser() {
  const userId = useCurrentUserId();

  return useQuery({
    queryKey: [userKey, userId],

    queryFn: () =>
      api.auth.AuthService.UserGet({ id: userId! }).then(
        (el) => el as types.FullUserDTO & { permissions: string[] }
      ),

    enabled: !!userId,
  });
}

export function preloadCurrentUser(queryClient: QueryClient, userId: string) {
  return queryClient.fetchQuery({
    queryKey: [userKey, userId],
    queryFn: () => api.auth.AuthService.UserGet({ id: userId }),
    staleTime: CACHE_STALE_TIME,
  });
}

export function useFrontendConfig(
  user: types.FullUserDTO | undefined,
  onSuccessCb: (config: frontendconfig.FrontendConfig) => void
) {
  return useQuery({
    queryKey: [configKey],
    enabled: !!user,
    queryFn: () =>
      apiCustom.GetFrontendConfig({}).then((el) => {
        if (!el.config) {
          return {};
        }
        updateSensitiveValues(el.config);
        updatePolicyDefaults();
        onSuccessCb(el.config);
        return el.config;
      }),
  });
}

// load sensitive env variables after login
export function preloadFrontendConfig(queryClient: QueryClient) {
  return queryClient.fetchQuery({
    queryKey: [configKey],
    queryFn: () =>
      apiCustom.GetFrontendConfig({}).then((el) => {
        if (!el.config) {
          return {};
        }
        updateSensitiveValues(el.config);
        updatePolicyDefaults();
        return el.config;
      }),
  });
}

export function useCurrentUserId() {
  return useSelector((store: any) => store.auth?.user?.id as string | undefined);
}

export function useCurrentUserEmail() {
  return useSelector((store: any) => store.auth?.user?.email as string | undefined);
}

export function preloadUserPermissions(queryClient: QueryClient, userId: string) {
  return queryClient.fetchQuery({
    queryKey: [userKey, "permissions", userId],

    queryFn: async () => {
      const roles = await sendGQLRequest(GetUserPermissionsDocument, { userId });
      const currentUser = await preloadCurrentUser(queryClient, userId);
      const permissions = roles.subject_permission
        .map((el) => el.permission)
        .filter(Boolean) as string[];
      return {
        roles: currentUser?.roles,
        permissions,
      };
    },

    staleTime: CACHE_STALE_TIME,
  });
}

const permissionsKey = "permissions";
export function useUserPermissions(userId: string) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [permissionsKey, userId],

    queryFn: async () => {
      const [roles, currentUser] = await Promise.all([
        sendGQLRequest(GetUserPermissionsDocument, { userId }),
        preloadCurrentUser(queryClient, userId),
      ]);

      const permissions = roles.subject_permission
        .map((el) => el.permission)
        .filter(Boolean) as string[];

      return {
        roles: currentUser?.roles,
        permissions,
      };
    },

    enabled: !!userId,
    staleTime: CACHE_STALE_TIME,
  });
}

const cyberhavenEmailPostfixes = ["cyberhaven.com", "cyberhaven.io"];
export function useIsCyberhavenUser() {
  const currentUser = useSelector((store: any) => store.auth?.user);

  return cyberhavenEmailPostfixes.some((postfix) => currentUser?.email.endsWith(postfix));
}

export function useCurrentUserScope() {
  const userId = useCurrentUserId();

  return useQuery({
    queryKey: ["user-scope", userId],
    queryFn: () => sendGQLRequest(GetCurrentUserScopeDocument, { user_id: userId! }),
    enabled: !!userId,
  });
}
