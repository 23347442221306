import { permissions } from "api/permissions";

export const ROLE_ADMIN = {
  id: "25a33225-be5a-4c8b-ad4b-5ce10862e12d",
  name: "Global Admin",
  description: "Admin has all of the defined permissions and more",
  assigned_permissions_aggregate: { aggregate: { count: permissions.length } },
};

export const OLD_USER_ROLE = {
  id: null,
  name: "User",
  description: "Old user permissions",
};

export const EMPTY_ROLE = {
  id: null,
  name: "N/A",
  description: "Roles are missing for this user",
};
